<template>
    <div class="dropdown pointer" :class="{'mb-2': mode === 'verbose'}">
        <button type="button"
                :id="componentId"
                :disabled="isButtonDisabled()"
                aria-expanded="false"
                class="btn dropdown-toggle text-dark"
                :class="{'btn-outline-light': mode === 'verbose', 'ps-0': mode === 'compact'}"
                data-bs-toggle="dropdown">
            <BookingStatus
                :status="draftBooking.status"
                :class="{'me-2': mode === 'verbose'}"
                hide-tool-tip
            />
            <template v-if="mode === 'verbose'">{{ title }}</template>
        </button>
        <ul :aria-labelledby="componentId" class="dropdown-menu" :class="mode">
            <template v-for="(label, status) in filteredStatuses()">
                <li v-if="status.toString() !== draftBooking.status">
                    <a class="dropdown-item"
                       :class="`status-${status}`"
                       href="javascript: void(0)"
                       @click="updateStatus(status)">
                        <BookingStatus
                            :status="status"
                            class="me-1"
                            hide-tool-tip
                        />
                        {{ label }}
                    </a>
                </li>
            </template>
        </ul>
    </div>
    <p v-if="saving && mode === 'verbose'" class="text-success">Saving status ...</p>
</template>

<script setup lang="ts">
import Booking from "@classes/Booking";
import BookingStatus from "@components/BookingStatus.vue";
import { pick, pickBy } from "lodash";
import { DateTime } from "luxon";
import { computed, inject, onMounted, ref } from "vue";
import { uuid } from "vue-uuid";

const componentId = ref(uuid.v4());
const props = withDefaults(defineProps<{
        booking: App.Models.Booking;
        mode?: "verbose" | "compact";
    }>(),
    {
        mode: 'verbose'
    }
);

const draftBooking = ref<App.Models.Booking>(props.booking);
const statuses = ref<string[]>(window.enums.bookingStatus);
const saving = ref(false);
const toast = inject<any>("toast");
const cancelBooking = inject<any>("cancelBooking");
const originalStatus = ref('');

const updateStatus = function (status: string) {
    if (status == "cancelled") {
        cancelBooking.submitFor(props.booking);
        return;
    }

    if (originalStatus.value === 'cancelled' &&
        status === 'future'
    ) {
        Booking.restoreCancelled(draftBooking.value)
            .then((response) => {
                toast.success("Booking restored");
            })
            .catch((error) => {
                toast.error(
                    error?.response?.data?.message ??
                    "There was an error restoring the cancelled booking"
                );
            }).finally(() => {
            draftBooking.value.status = status;
        });

        return;
    }

    draftBooking.value.status = status;

    saving.value = true;

    Booking.update(draftBooking.value, { status, })
        .then((response) => {
            toast.success("Booking status updated");
        })
        .catch((error) => {
            toast.error(
                error?.response?.data?.message ??
                "There was an error updating the booking status"
            );
        })
        .finally(() => {
            saving.value = false;
        });
};

const title = computed(() => {
    return statuses.value[draftBooking.value.status];
});

const isButtonDisabled = () => {
    if (draftBooking.value.deleted_at) {
        return true;
    }
    if (draftBooking.value.status === 'cancelled') {
        const bookingTime = draftBooking.value.planned_destination_time ??
            draftBooking.value.planned_origin_time ??
            draftBooking.value.estimated_destination_time ??
            draftBooking.value.estimated_origin_time;

        if (!bookingTime) {
            return false;
        }
        // cannot revert cancellation if its already booking's end of day
        // cron already start generating invoice etc
        if (DateTime.now() > DateTime.fromISO(bookingTime).endOf('day')) {
            return true;
        }
    }

    return false;
}

const filteredStatuses = (): Object => {
    if (draftBooking.value.status == 'cancelled') {
        return pick(statuses.value, ['future']);
    }

    return pickBy(statuses.value, (value, key) => draftBooking.value.status !== key);
}

onMounted(() => {
    originalStatus.value = draftBooking.value.status;
});
</script>

<style scoped>
</style>
