<template>
    <div
        id="cancelBookingModal"
        aria-hidden="true"
        aria-labelledby="cancelBookingModalLabel"
        class="modal fade"
        tabindex="-1"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="cancelBookingModalLabel" class="modal-title">Cancel Booking {{ booking.uuid }}?</h5>
                    <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
                </div>
                <div class="modal-body">
                    <SimpleBookingCard class="mb-3" :booking="booking" />

                    <div class="mb-2">
                        <FormSelect
                            v-if="reason"
                            :key="reason.uuid"
                            v-model:selection="reason"
                            :options="cancellationReasons.map(reason => {
                                return {
                                    value: reason.uuid,
                                    label: reason.name
                                }
                            })"
                            label="Cancellation Reason"
                            placeholder="Select Reason"
                        />
                    </div>

                    <div class="mb-2">
                        <FormTextarea
                            v-if="comment"
                            :key="comment.uuid"
                            v-model:input="comment"
                            label="Comment"
                            maxlength="250"
                            placeholder="(optional)"
                        />
                    </div>

                    <div v-if="booking.sequence_identifier !== null">
                        <div class="form-check mt-2">
                            <input id="cancelAllInSequenceCheckbox" v-model="cancelAllInSequence" class="form-check-input"
                                   type="checkbox" value="">
                            <label class="form-check-label" for="cancelAllInSequenceCheckbox">
                                Cancel all bookings on the same day linked with this one
                            </label>
                        </div>
                        <div class="mt-2" v-if="cancelAllInSequence && !loadingSequentialBookings">
                            {{ sequentialBookings.length }} additional
                            {{ sequentialBookings.length === 1 ? 'booking' : 'bookings' }} will also be cancelled
                            <a @click="showSequentialBookings = !showSequentialBookings" href="javascript: void(0)">
                                <template v-if="showSequentialBookings">
                                    Hide details
                                </template>
                                <template v-else>
                                    See details
                                </template>
                            </a>
                            <div class="alert alert-danger mt-2" v-if="cancellationFees.length > 0">
                                <strong>Please Note</strong>:  By selecting to cancel all same day linked bookings, the entirety of the cancellation fee will be applied to first booking in the sequence only.
                                If you wish to apply a cancellation fee to each booking, please cancel each booking separately.
                            </div>
                            <transition name="basic-fade">
                                <div class="card shadow-none border mt-2" v-if="showSequentialBookings">
                                    <div class="card-body p-2">
                                        <div class="d-flex flex-column">
                                            <template v-for="sequentialBooking in sequentialBookings">
                                                <SimpleBookingCard class="mb-1 shadow-none border" :booking="sequentialBooking" />
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div v-if="booking.group_booking_identifier !== null">
                        <div class="form-check mt-2">
                            <input id="cancelAllInGroupCheckbox" v-model="cancelAllInGroup" class="form-check-input"
                                   type="checkbox" value="">
                            <label class="form-check-label" for="cancelAllInGroupCheckbox">
                                Cancel all bookings in this group
                            </label>
                        </div>
                        <div class="mt-2" v-if="cancelAllInGroup && !loadingGroupedBookings">
                            {{ groupedBookings.length }} additional
                            {{ groupedBookings.length === 1 ? 'booking' : 'bookings' }} will also be cancelled
                            <a @click="showGroupedBookings = !showGroupedBookings" href="javascript: void(0)">
                                <template v-if="showGroupedBookings">
                                    Hide details
                                </template>
                                <template v-else>
                                    See details
                                </template>
                            </a>
                            <transition name="basic-fade">
                                <div class="card shadow-none border mt-2" v-if="showGroupedBookings">
                                    <div class="card-body p-2">
                                        <div class="d-flex flex-column">
                                            <template v-for="groupedBooking in groupedBookings">
                                                <SimpleBookingCard class="mb-1 shadow-none border" :booking="groupedBooking" />
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <div v-if="booking.reschedule_identifier !== null">
                        <div class="form-check mt-2">
                            <input
                                id="cancelAllRescheduledCheckbox"
                                v-model="cancelAllInSchedule"
                                class="form-check-input"
                                type="checkbox"
                                value=""
                            >
                            <label class="form-check-label" for="cancelAllRescheduledCheckbox">
                                Cancel all future rescheduled bookings linked with this one
                            </label>
                        </div>
                        <div class="mt-2" v-if="cancelAllInSchedule && !loadingRescheduledBookings">
                            {{ rescheduledBookings.length }} additional
                            {{ rescheduledBookings.length === 1 ? 'booking' : 'bookings' }} will also be cancelled
                            <a @click="showRescheduledBookings = !showRescheduledBookings" href="javascript: void(0)">
                                <template v-if="showRescheduledBookings">
                                    Hide details
                                </template>
                                <template v-else>
                                    See details
                                </template>
                            </a>
                            <div class="alert alert-danger mt-2" v-if="cancellationFees.length > 0">
                                <strong>Please Note</strong>:  By selecting to cancel all future rescheduled bookings, the entirety of the cancellation fee will be applied to first booking in the recurrence only.
                                If you wish to apply a cancellation fee to each booking, please cancel each booking separately.
                            </div>
                            <transition name="basic-fade">
                                <div class="card shadow-none border mt-2" v-if="showRescheduledBookings">
                                    <div class="card-body p-2">
                                        <div class="d-flex flex-column">
                                            <template v-for="rescheduledBooking in rescheduledBookings">
                                                <SimpleBookingCard class="mb-1 shadow-none border" :booking="rescheduledBooking" />
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>

                    <template v-if="cancellationFees.length > 0">
                        <div class="form-check mt-2">
                            <input id="waiveBookingCancellationFeeCheckbox" v-model="waiveFee" class="form-check-input"
                                   type="checkbox" value="">
                            <label class="form-check-label" for="waiveBookingCancellationFeeCheckbox">
                                Waive cancellation fee
                            </label>
                        </div>
                        <div class="py-2 px-4 mt-2 rounded border-1 bg-light">
                            <div class="my-2">
                                <strong>Cancellation Fees</strong>
                            </div>
                            <template v-if="!calculatingFees">
                                <template v-for="fee in cancellationFees">
                                    <div class="mb-2">
                                        <div class="d-flex justify-content-between">
                                            <div>{{ fee.description }}</div>
                                            <div>
                                                {{ $filters.currency(fee.amount) }}
                                                <template v-if="fee.gst > 0">
                                                    (+ {{ $filters.currency(fee.gst) }} GST)
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-if="waiveFee" class="d-flex justify-content-between mb-2 border-top pt-2">
                                    <div><strong>Fee Waived</strong></div>
                                    <div>-{{ $filters.currency(totalFee) }}</div>
                                </div>
                                <div class="d-flex justify-content-between border-top pt-2">
                                    <div><strong>Total</strong></div>
                                    <div><strong>{{ $filters.currency(waiveFee ? 0 : totalFee) }}</strong></div>
                                </div>
                            </template>
                            <div class="d-flex align-items-center justify-content-center py-2">
                                <Spinner v-if="calculatingFees" />
                            </div>
                        </div>
                    </template>

                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
                    <Button :loading="cancelling" :disabled="!reason || reason.value === null" color="danger" @click="cancelBooking">Confirm Cancellation</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CancelBooking"
}
</script>
<script setup>
import {onMounted, ref, inject, computed, watch} from "vue";
import Button from "@components/Button.vue"
import FormSelect from "@components/Forms/FormSelect.vue";
import FormTextarea from "@components/Forms/FormTextarea.vue";
import {uuid} from "vue-uuid";
import Spinner from "@components/Spinner.vue"
import SimpleBookingCard from "@components/Bookings/SimpleBookingCard.vue";
import { useBookingCancellationReasonStore } from "@stores/BookingCancellationReasonStore";
import {isEmpty} from "lodash";

const axios = inject("axios");
const toast = inject('toast')

const cancellationModal = ref(null)
const booking = inject('bookingToBeCancelled', {})
const cancellationReasons = ref([])

const reason = ref(null)
const comment = ref(null)
const waiveFee = ref(false)

const bookingCancellationReasonStore = useBookingCancellationReasonStore();

const showSequentialBookings = ref(false)
const cancelAllInSequence = ref(false)
const sequentialBookings = ref([])
const loadingSequentialBookings = ref(false)
const getSequentialBookings = function () {
    loadingSequentialBookings.value = true
    axios
        .get(route('api.bookings.cancel', {booking: booking.value.uuid}), {
            params: {
                cancel_all_in_sequence: 1,
                list: 1,
            }
        })
        .then(response => {
            sequentialBookings.value = response.data.data;
        })
        .catch(error => {
            toast.error('An error occurred, please try again later.')
            console.error(error.message);
        })
        .finally(() => {
            loadingSequentialBookings.value = false
        })
}

watch(cancelAllInSequence, (value) => {
    if (value) {
        getSequentialBookings()
    }
})

const showRescheduledBookings = ref(false)
const cancelAllInSchedule = ref(false)
const rescheduledBookings = ref([])
const loadingRescheduledBookings = ref(false)
const getRescheduledBookings = function () {
    loadingRescheduledBookings.value = true
    axios
        .get(route('api.bookings.cancel', {booking: booking.value.uuid}), {
            params: {
                cancel_all_in_schedule: 1,
                list: 1,
            }
        })
        .then(response => {
            rescheduledBookings.value = response.data.data.filter(b => b.uuid !== booking.value.uuid)
        })
        .catch(error => {
            toast.error('An error occurred, please try again later.')
            console.error(error.message);
        })
        .finally(() => {
            loadingRescheduledBookings.value = false
        })
}

watch(cancelAllInSchedule, (value) => {
    if (value) {
        getRescheduledBookings()
    }
})

const showGroupedBookings = ref(false)
const cancelAllInGroup = ref(false)
const groupedBookings = ref([])
const loadingGroupedBookings = ref(false)
const getGroupedBookings = function () {
    loadingGroupedBookings.value = true
    axios
        .get(route('api.bookings.cancel', {booking: booking.value.uuid}), {
            params: {
                cancel_all_in_group: true,
                list: true
            }
        })
        .then(response => {
            groupedBookings.value = response.data.data;
        })
        .catch(error => {
            toast.error('An error occurred, please try again later.')
            console.error(error.message);
        })
        .finally(() => {
            loadingGroupedBookings.value = false
        })
}

watch(cancelAllInGroup, (value) => {
    if (value) {
        getGroupedBookings()
    }
})

const setDefaultFormValues = () => {
    reason.value = {
        uuid: uuid.v4(),
        value: null,
        errors: [],
        required: true,
    }
    comment.value = {
        uuid: uuid.v4(),
        value: '',
        errors: [],
        required: false,
    }
    waiveFee.value = false
}

watch(booking, (newValue) => {
    setDefaultFormValues()
    if (isEmpty(newValue)) {
        return
    }

    let bookingInfoModal = bootstrap.Modal.getInstance(document.getElementById('bookingDetailsModal'));
    bookingInfoModal.hide()
    cancellationModal.value.show()
})

const calculatingFees = ref(false)
const cancellationFees = ref([])
const totalFee = computed(() => {
    return cancellationFees.value.reduce((total, fee) => total + fee.amount + fee.gst, 0)
})
const calculateCancellationFees = () => {

    if (!reason.value || !reason.value.value) {
        cancellationFees.value = []
        return
    }

    calculatingFees.value = true
    axios
        .get(route('api.bookings.calculate-cancellation-fees', {booking: booking.value.uuid, reason: reason.value.value}))
        .then(response => {
            cancellationFees.value = response.data
        })
        .catch(error => {
            toast.error('Failed to get cancellation fees')
            console.error(error.message);
        })
        .finally(() => {
            calculatingFees.value = false
        })
}

watch(reason, () => {
    calculateCancellationFees()
})

const cancelling = ref(false)
const cancelBookingPlugin = inject('cancelBooking')

const cancelBooking = function () {
    cancelling.value = true;
    axios.post(route('api.bookings.cancel', {booking: booking.value.uuid}), {
        reason: reason.value.value,
        comment: comment.value.value,
        waive_fee: waiveFee.value,
        cancel_all_in_sequence: cancelAllInSequence.value,
        cancel_all_in_group: cancelAllInGroup.value,
        cancel_all_in_schedule: cancelAllInSchedule.value,
    }).then(() => {
        toast.success('You successfully cancelled booking **' + booking.value.uuid + '**.')
        booking.value.updated_at = Date.now()
        cancelBookingPlugin.updateCancelledBooking(booking.value)
        cancellationModal.value.hide()
        if (cancelBookingPlugin.reloadOnSubmit()) {
            window.location.reload()
        }
    }).catch(error => {
        toast.error('An error occurred, please try again later.')
        console.error(error.message);
    }).finally(() => {
        cancelling.value = false
    })
}

const getCancellationReasons = async () => {
    cancellationReasons.value = await bookingCancellationReasonStore.sync();
}

onMounted(() => {
    getCancellationReasons()
    cancellationModal.value = new bootstrap.Modal(document.getElementById('cancelBookingModal'))
    document.getElementById('cancelBookingModal').addEventListener('hide.bs.modal', () => {
        booking.value = {}
    })
})
</script>

<style scoped>

</style>
