import ActivityLogsModalPlugin from "@components/ActivityLogsModalPlugin";
import BookingDetailsModalPlugin from "@components/Bookings/BookingDetailsModal";
import CancelBookingPlugin from "@components/Bookings/CancelBooking/index";
import RescheduleBookingPlugin from "@components/Bookings/RescheduleBooking/index";
import CopyJourneyPlugin from "@components/CopyJourneyPlugin";
import DriverPositionUpdateModalPlugin from "@components/DriverPositionUpdateModalPlugin";
import ToastPlugin from "@components/toast/index";
import TooltipPlugin from "@components/Tooltip/index";
import * as Sentry from '@sentry/vue';
import Alpine from "alpinejs";
import axios from "axios";
import * as bootstrap from 'bootstrap';
import "bootstrap/dist/js/bootstrap.bundle.min";
import $ from "jquery";
import { isPossiblePhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { Settings } from "luxon";
import { vClickOutsideInstall } from "maz-ui";
import moment from "moment-timezone";
import { createPinia, Pinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { App, createApp, defineAsyncComponent, Directive } from "vue";
import VueAxios from "vue-axios";
import HaccMdsReportExport from "@components/Reports/HaccMds/HaccMdsReportExport.vue";

import { IMaskDirective } from 'vue-imask';
import UUID from "vue-uuid";
import VueApexCharts from "vue3-apexcharts";
import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory, Router } from 'vue-router'

declare global {
    interface Window {
        [key: string]: any;
    }
}

window.$ = window.jQuery = $;

window.bootstrap = bootstrap;

window.moment = moment;

window.Alpine = Alpine;
Alpine.start();

const pinia: Pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const router: Router = createRouter({
    history: createWebHashHistory(),
    routes: []
})
const app: App<Element> = createApp({});
app.use(router);
window.app = app;

axios.defaults.xsrfCookieName = import.meta.env.VITE_XSRF_TOKEN_COOKIE ?? 'XSRF-TOKEN';

Settings.defaultZone = window.timezone;
Settings.defaultLocale = 'en-AU'

//@ts-ignore
if (import.meta.env.VITE_SENTRY_DSN_PUBLIC) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        debug: import.meta.env.VITE_APP_DEBUG,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        tracingOptions: {
            trackComponents: true,
        },
        tracePropagationTargets: [
            "localhost",
            import.meta.env.VITE_HOST,
            /^\//,
        ],
        logErrors: import.meta.env.VITE_APP_DEBUG,
        attachProps: true,
        attachStacktrace: true,
        ignoreErrors: ['QuotaExceededError', 'NS_ERROR_DOM_QUOTA_REACHED']
    });
}

app.use(pinia);
app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios);
app.use(UUID);
app.use(VueApexCharts);
app.use(ToastPlugin);
//@ts-ignore
app.use(TooltipPlugin);
//@ts-ignore
app.use(BookingDetailsModalPlugin);
//@ts-ignore
app.use(ActivityLogsModalPlugin);
//@ts-ignore
app.use(CopyJourneyPlugin);
//@ts-ignore
app.use(DriverPositionUpdateModalPlugin);
app.use(vClickOutsideInstall);
app.use(CancelBookingPlugin);
app.use(RescheduleBookingPlugin);

app.directive("popover", {
    mounted(el) {
        new bootstrap.Popover(el);
    },
});

app.directive("dropdown", {
    mounted(el) {
        new bootstrap.Dropdown(el);
    },
});

app.directive("mask", IMaskDirective as Directive);

app.mixin({
    data() {
        return {
            moment: moment,
        };
    },
    computed: {
        timezone() {
            return this.moment.tz.guess() ?? "Australia/Sydney"; // default to Sydney
        },
    },
    methods: {
        //@ts-ignore
        route: (name, params, absolute) => route(name, params, absolute),
        groupBy: function (xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        global_asset(path) {
            let base_path = window._globalAsset || "";
            return base_path + path;
        },
        asset(path) {
            let base_path = window._asset || "";
            return base_path + path;
        },
        validEmail(string) {
            // noinspection RegExpRedundantEscape
            return String(string)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        inPast(firstDateTime, secondDateTime = null) {
            const first = new Date(firstDateTime);
            const second = secondDateTime
                ? new Date(secondDateTime)
                : new Date();

            return first <= second;
        },
        getPageFromUrl(url) {
            return url !== null
                ? parseInt(url.substring(url.indexOf("page=") + 5))
                : null;
        },
        bookingStatusBadgeClass(status) {
            switch (status) {
                case "verified":
                    return "bg-success";
                case "standby":
                    return "bg-info";
                case "future":
                    return "bg-primary";
                case "cancelled":
                case "unmet":
                    return "bg-danger";
                case "waiting_verification":
                case "transferred":
                case "posted":
                    return "bg-secondary";
            }
        },
    },
});

app.component("alerts", defineAsyncComponent(() => import('@components/Alerts.vue')));
app.component("dark-mode-toggle", defineAsyncComponent(() => import('@components/DarkModeToggle.vue')));
app.component("vehicle-positions", defineAsyncComponent(() => import('@components/VehiclePositions.vue')));
app.component("journey-tree-view", defineAsyncComponent(() => import('@components/JourneyTree/JourneyTreeView.vue')));
app.component("booking-form", defineAsyncComponent(() => import('@components/BookingForm/BookingForm.vue')));
app.component("dispatch-feed", defineAsyncComponent(() => import('@components/DispatchFeed/DispatchFeed.vue')));
app.component("dispatch-unallocated-bookings", defineAsyncComponent(() => import('@components/Dispatch/UnallocatedBookings.vue')));
app.component("booking-allocation-form", defineAsyncComponent(() => import('@components/Allocations/BookingAllocationForm.vue')));
app.component("login-form", defineAsyncComponent(() => import('@components/Auth/LoginForm.vue')));
app.component("user-settings", defineAsyncComponent(() => import('@components/UserSettings/UserSettings.vue')));
app.component("list-funding-types", defineAsyncComponent(() => import('@components/FundingTypes/ListFundingTypes.vue')));
app.component("show-funding-type", defineAsyncComponent(() => import('@components/FundingTypes/ShowFundingType.vue')));
app.component("client-profile", defineAsyncComponent(() => import('@components/Clients/ClientProfile.vue')));
app.component("client-list", defineAsyncComponent(() => import('@components/Clients/ClientList.vue')));
app.component("create-client", defineAsyncComponent(() => import('@components/Clients/CreateClient.vue')));
app.component("vehicle-list", defineAsyncComponent(() => import('@components/Vehicles/VehicleList.vue')));
app.component("vehicle-edit", defineAsyncComponent(() => import('@components/Vehicles/VehicleEdit.vue')));
app.component("vehicle-create", defineAsyncComponent(() => import('@components/Vehicles/VehicleCreate.vue')));
app.component("vehicle-profile", defineAsyncComponent(() => import('@components/Vehicles/VehicleProfile.vue')));
app.component("driver-list", defineAsyncComponent(() => import('@components/Drivers/DriverList.vue')));
app.component("driver-create", defineAsyncComponent(() => import('@components/Drivers/DriverCreate.vue')));
app.component("driver-edit", defineAsyncComponent(() => import('@components/Drivers/DriverEdit.vue')));
app.component("driver-profile", defineAsyncComponent(() => import('@components/Drivers/DriverProfile/DriverProfile.vue')));
app.component("user-avatar", defineAsyncComponent(() => import('@components/Users/UserAvatar.vue')));
app.component("svg-icon", defineAsyncComponent(() => import('@jamescoyle/vue-icon')));
app.component("bookings-list", defineAsyncComponent(() => import('@components/Bookings/BookingsList.vue')));
app.component("chat-box", defineAsyncComponent(() => import('@components/ChatBox/ChatBox.vue')));
app.component("driver-chat", defineAsyncComponent(() => import('@components/ChatBox/DriverChat.vue')));
app.component("driver-app-settings", defineAsyncComponent(() => import('@components/Settings/DriverAppSettings.vue')));
app.component("general-settings", defineAsyncComponent(() => import('@components/Settings/GeneralSettings.vue')));
app.component("booking-settings", defineAsyncComponent(() => import('@components/Settings/BookingSettings.vue')));
app.component("vehicle-type-settings", defineAsyncComponent(() => import('@components/Settings/VehicleTypeSettings.vue')));
app.component("unallocated-bookings", defineAsyncComponent(() => import('@components/Allocations/UnallocatedBookings.vue')));
app.component("two-factor-setup", defineAsyncComponent(() => import('@components/Auth/TwoFactorSetup.vue')));
app.component("users-list", defineAsyncComponent(() => import('@components/Users/UsersList.vue')));
app.component("user-details", defineAsyncComponent(() => import('@components/Users/UserDetails.vue')));
app.component("accept-invitation", defineAsyncComponent(() => import('@components/Auth/AcceptInvitation.vue')));
app.component("booking-details", defineAsyncComponent(() => import('@components/Bookings/BookingDetails.vue')));
app.component("region-filter", defineAsyncComponent(() => import('@components/RegionFilter.vue')));
app.component("alert-modal", defineAsyncComponent(() => import('@components/AlertModal.vue')));
app.component("xero-settings", defineAsyncComponent(() => import('../../app/Modules/AccountingService/Xero/resources/js/XeroSettings.vue')));
app.component("verifications", defineAsyncComponent(() => import('@components/Verifications/Verifications.vue')));
app.component("locations-list", defineAsyncComponent(() => import('@components/Locations/LocationsList.vue')));
app.component("list-booking-cancellation-reasons", defineAsyncComponent(() => import('@components/BookingCancellationReasons/ListBookingCancellationReasons.vue')));
app.component("list-booking-service-types", defineAsyncComponent(() => import('@components/BookingServiceTypes/ListBookingServiceTypes.vue')));
app.component("list-funding-rules", defineAsyncComponent(() => import('@components/FundingRules/ListFundingRules.vue')));
app.component("create-or-edit-funding-rule", defineAsyncComponent(() => import('@components/FundingRules/CreateOrEditFundingRule.vue')));
app.component("data-exchange-dashboard", defineAsyncComponent(() => import('@components/DataExchange/DataExchangeDashboard.vue')));
app.component("tfnsw-service-data-export", defineAsyncComponent(() => import('@components/Reports/Tfnsw/TfnswServiceDataExport.vue')));
app.component("list-payers", defineAsyncComponent(() => import('@components/Payers/ListPayers.vue')));
app.component("confirm-booking-timings", defineAsyncComponent(() => import('@components/Bookings/ConfirmBookingTimings.vue')));
app.component("list-location-types", defineAsyncComponent(() => import('@components/LocationTypes/ListLocationTypes.vue')));
app.component("notification-settings-list", defineAsyncComponent(() => import('@components/Notifications/NotificationSettingsList.vue')));
app.component("query-builder", defineAsyncComponent(() => import('@components/Reports/CustomReport/QueryBuilder.vue')));
app.component("booking-fare-adjustment", defineAsyncComponent(() => import('@components/Services/BookingFareAdjustment/BookingFareAdjustment.vue')));
app.component("booking-tags-list", defineAsyncComponent(() => import('@components/Bookings/BookingTags/index.vue')));
app.component("admin-tenant-switcher", defineAsyncComponent(() => import('@components/Admin/Auth/AdminTenantSwitcher.vue')));
app.component("restrictions-list", defineAsyncComponent(() => import('@components/Locations/Restrictions/RestrictionsList.vue')));
app.component("revert-booking-request-list", defineAsyncComponent(() => import('@components/Bookings/RevertBookingRequestList.vue')));
app.component("tutorial-list", defineAsyncComponent(() => import('@components/Tutorials/TutorialList.vue')));
app.component("export-list", defineAsyncComponent(() => import('@components/Reports/ExportList.vue')));
app.component("attachment-list", defineAsyncComponent(() => import('@components/Attachments/AttachmentsList.vue')));
app.component("data-import-index", defineAsyncComponent(() => import('@components/Services/DataImport/DataImportIndex.vue')));
app.component("vehicle-configuration-design-page", defineAsyncComponent(() => import('@components/Vehicles/Configuration/Design/VehicleConfigurationDesignPage.vue')));
app.component("manage-group-booking-templates", defineAsyncComponent(() => import('@components/GroupBookings/ManageGroupBookingTemplates.vue')));
app.component("group-booking-template", defineAsyncComponent(() => import('@components/GroupBookings/GroupBookingTemplateForm.vue')));
app.component("hacc-mds-report-export", HaccMdsReportExport);

app.config.globalProperties.$filters = {
    currency(value) {
        if (typeof value !== "number" && isNaN(parseFloat(value))) {
            return value;
        }

        value = parseFloat(value);

        const formatter = new Intl.NumberFormat("en-AU", {
            style: "currency",
            currency: "AUD",
            minimumFractionDigits: 2,
        });

        return formatter.format(value);
    },
    rego(rego) {
        return (
            rego.slice(0, Math.floor(rego.length / 2)) +
            "-" +
            rego.slice(Math.floor(rego.length / 2), rego.length)
        );
    },
    phone(phoneNumber) {
        if (
            typeof phoneNumber !== "string" ||
            phoneNumber.trim().length === 0 ||
            isPossiblePhoneNumber(phoneNumber, "AU") !== true
        ) {
            return phoneNumber;
        }

        const phone = parsePhoneNumber(phoneNumber, "AU");
        return phone.formatInternational();
    },
    phone_url(phoneNumber) {
        if (
            typeof phoneNumber !== "string" ||
            phoneNumber.trim().length === 0 ||
            isPossiblePhoneNumber(phoneNumber, "AU") !== true
        ) {
            return "";
        }

        const phone = parsePhoneNumber(phoneNumber, "AU");
        return phone.getURI();
    },
    ucwords(str) {
        const words = str.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
        }
        return words.join(" ");
    },
    booking_status(statusCode) {
        switch (statusCode) {
            case "future":
                return "Future";
            case "verified":
                return "Verified";
            case "cancelled":
                return "Cancelled";
            case "standby":
                return "Wait-listed";
            case "waiting_verification":
                return "Awaiting Verification";
            case "unmet":
                return "Unmet";
            case "transferred":
                return "Transferred";
            case "posted":
                return "Posted";
        }
    },
    format_minutes(minutes) {
        return moment.duration(minutes, "minutes").humanize();
    },
    highlight(string: string, query: string): string {
        if (!string || !query) {
            return string;
        }
        query = query.replace(new RegExp("\\\\", "g"), "\\\\");
        const check = new RegExp(query.trim(), "ig");
        let result = string
            .toString()
            .replace(
                check,
                (matchedText) =>
                    '<span class="fw-bolder">' + matchedText + "</span>"
            );
        return result ?? string;
    },
    comma_separated_thousands(number) {
        if (!number) {
            return "0";
        }

        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format_datetime(date: any, format: string) {
        if (date == null || date === "") {
            return [];
        }
        const dateTime = new Date(date);
        const locale = new Intl.DateTimeFormat().resolvedOptions().locale;
        let options = { timeZone: window.timezone };
        switch (format) {
            case "short":
                options["dateStyle"] = "short";
                options["timeStyle"] = "short";
                break;
            case "long":
                options["dateStyle"] = "long";
                options["timeStyle"] = "long";
                break;
            case "short time":
                options["timeStyle"] = "short";
                break;
            case "medium time":
                options["timeStyle"] = "medium";
                break;
            case "long time":
                options["timeStyle"] = "long";
                break;
            case "short date":
                options["dateStyle"] = "short";
                break;
            case "medium date":
                options["dateStyle"] = "medium";
                break;
            case "long date":
                options["dateStyle"] = "long";
                break;
            case "full date":
                options["dateStyle"] = "full";
                break;
            default:
                options["dateStyle"] = "medium";
                options["timeStyle"] = "medium";
                break;
        }
        return new Intl.DateTimeFormat(locale, options).format(dateTime);
    },
    format_distance(meters) {
        return (
            (meters / 1000)
                .toFixed(1)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " km"
        );
    },
    title_case(string) {
        string = string.replace("_", " ");
        return string.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    },
};
app.directive("focus", {
    mounted(el, binding) {
        if (binding.value) {
            el.focus();
        }
    },
});
app.mount("#app");
