import axios, { AxiosResponse } from "axios";
import { DataTableInterface } from "@customTypes/DataTableTypes";
import ApiResourceResponseInterface = App.Pagination.ApiResourceResponseInterface;
import DriverResource = App.Resource.DriverResource;

export default class Driver {
    static async index(params: Partial<DataTableInterface> | { active: boolean, is_active_now?: boolean | undefined }): Promise<AxiosResponse<ApiResourceResponseInterface<DriverResource[]>>> {
        return await axios.get<ApiResourceResponseInterface<DriverResource[]>>(route('api.drivers.index', params));
    }

    static async show(driverUuid: string) {
        return await axios.get<DriverResource>(route("api.drivers.show", { driver: driverUuid }));
    }

    static async search(params:{ query: string, amount?: number, active: boolean, page?: number, is_active_now?: boolean | undefined, location_ids?: string[]}): Promise<AxiosResponse<ApiResourceResponseInterface<DriverResource[]>>> {
        return await axios.get<ApiResourceResponseInterface<DriverResource[]>>(route('api.drivers.search', params));
    }

    static async logout(driver:DriverResource) {
        return await axios.post(route("api.drivers.logout"), { driver: driver.uuid,})
    }
}
