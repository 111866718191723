import {ref, reactive, Ref} from 'vue'
import Toasts from './Toasts.vue'
import {DateTime} from "luxon"

export interface ToastInterface {
    startTimeout(messageData: any, startFrom?: number): void,

    success(message: string, options?: {}): void,

    warning(message: string, options?: {}): void,

    error(message: string, options?: {}): void,

    info(message: string, options?: {}): void,

    send(message: string, type: "info" | "warning" | "danger" | "success", options?: {}): void,
}

export interface ToastMessageInterface {
    id: number,
    message: string,
    type: string,
    createdAt: DateTime,
    animationFrame: number,
    progress: number,
    percentProgress: string,
    timeout: number,
    paused: boolean,
}

export interface ToastOptionsInterface {
    showProgress: boolean,
    timeout: number,
    closeable: boolean,
    progress: number,
    paused: boolean,
    animationFrame: number | null,
    title: string | null,
}

const ToastPlugin = {
    install: (app, customOptions = {}) => {
        const toast: Partial<ToastInterface> = {};

        const standardOptions: ToastOptionsInterface = {
            showProgress: true,
            timeout: 10000,
            closeable: true,
            progress: 0,
            paused: false,
            animationFrame: null,
            title: null,
        }

        const defaultOptions: ToastOptionsInterface = Object.assign({}, standardOptions, customOptions)

        const messages: any[] = reactive([])
        const count: Ref<number> = ref(0)

        const send = (message: string, type: "info" | "warning" | "danger" | "success", options: {} = {}): void => {
            let messageData: Partial<ToastMessageInterface> = {
                id: count.value++,
                message: message,
                type: type,
                createdAt: DateTime.now(),
            }

            messageData = Object.assign({}, messageData, defaultOptions, options)

            messages.unshift(messageData)

            if (messageData.timeout && messageData.timeout > 0) {
                toast.startTimeout?.(messageData);
            }
        }

        toast.startTimeout = (messageData, startFrom = 0) => {
            const startTime = performance.now()

            const start = () => {
                messageData.animationFrame = requestAnimationFrame(timestamp => {
                    const timeElapsed = timestamp + startFrom - startTime

                    if (!exists(messageData) || messageData.paused) {
                        cancelAnimationFrame(messageData.animationFrame)
                        return
                    }

                    if (timeElapsed < messageData.timeout) {
                        const progress = timeElapsed / messageData.timeout

                        messageData.progress = progress
                        messageData.percentProgress = parseFloat((progress * 100).toString()).toFixed(2);

                        start()

                        return
                    }

                    messageData.progress = 1
                    messageData.percentProgress = 100

                    cancelAnimationFrame(messageData.animationFrame)

                    remove(messageData)
                })
            }

            start()
        }

        const remove = (messageData: ToastMessageInterface): void => {
            const index: number = getIndex(messageData)
            messages.splice(index, 1)
        }

        const exists = (messageData: ToastMessageInterface): boolean => {
            const index: number = getIndex(messageData)

            return index !== -1
        }

        const getIndex = (messageData: ToastMessageInterface): number => {
            return messages.findIndex(
                message => message.id === messageData.id
            )
        }

        toast.success = (message: string, options: {} = {}): void => {
            send(message, 'success', options)
        }

        toast.warning = (message: string, options: {} = {}): void => {
            send(message, 'warning', options)
        }

        toast.info = (message: string, options: {} = {}): void => {
            send(message, 'info', options)
        }

        toast.error = (message: string, options: {} = {}): void => {
            send(message, 'danger', options)
        }

        app.provide('toast-messages', messages)

        app.provide('toast', toast)

        app.component('toasts', Toasts)
    }
}

export default ToastPlugin;

