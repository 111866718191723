<template>
    <div class="toast-container overflow-y-auto overflow-x-hidden" aria-live="polite" aria-atomic="true" ref="toastContainer">
        <TransitionGroup name="toast" tag="div">
            <div
                v-for="(message, index) in messages.slice(0, limit)"
                :key="index"
                class="toast show w-100 border-1 rounded position-relative shadow-sm mb-2 max-w-xl"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                @mouseover="pauseProgress(message)"
                @mouseout="resumeProgress(message)"
            >
                <div
                    v-if="message.timeout > 0 && message.showProgress"
                    class="progress progress-sm rounded-0 rounded-top active"
                >
                    <div :class="'bg-' + message.type" :style="{ width: (100-parseInt(message.percentProgress)) + '%'}" />
                </div>
                <div class="toast-header d-flex justify-content-between">
                    <div :class="'w-4 h-4 rounded-2 me-2 bg-' + message.type"></div>
                    <strong class="me-auto">
                        {{ message.title ? message.title : (message.type !== 'danger' ? $filters.title_case(message.type) : 'An Error Occurred') }}
                    </strong>
                    <div class="flex-shrink-1 d-flex align-items-center justify-content-end">
                        <small>{{ timeAgo(message) }} ago</small>
                        <button
                            v-if="message.closeable"
                            type="button"
                            class="btn-close ms-2 m-auto"
                            aria-label="Close"
                            @click="messages.splice(index, 1)"
                        ></button>
                    </div>
                </div>
                <div class="toast-body bg-white rounded-bottom w-100">
                    <Markdown :content="message.message"/>
                </div>
            </div>
            <template v-if="messages.length > limit">
                <div aria-atomic="true"
                     aria-live="assertive"
                     class="toast show w-100 border-1 rounded position-relative shadow-sm mb-2 max-w-xl"
                     role="alert">
                    <div class="toast-body bg-white rounded-bottom w-100 text-center">
                        <button class="btn btn-link" @click="limit = messages.length">Show all</button>
                    </div>
                </div>
            </template>
        </TransitionGroup>
    </div>
</template>


<script setup lang="ts">
    import { ToastInterface, ToastMessageInterface, ToastOptionsInterface } from "@components/toast/index";
    import { MaybeElementRef, onClickOutside } from "@vueuse/core";
    import {inject, onMounted, Ref, ref, watch} from 'vue'
    import humanizeDuration from 'humanize-duration'
    import {DateTime} from "luxon";
    import Markdown from '../Markdown.vue'
    import { useMessageStore } from '@stores/MessageStore';
    import { storeToRefs } from 'pinia';

    const messageStore = useMessageStore();
    const { toastMessages } = storeToRefs(messageStore);

    const messages:Ref<(ToastMessageInterface&ToastOptionsInterface)[]> = <Ref<(ToastMessageInterface&ToastOptionsInterface)[]>>inject('toast-messages', {})
    const toast:ToastInterface = <ToastInterface>inject('toast');

    const limit:Ref<number> = ref<number>(5);
    const timeNow:Ref<DateTime> = ref<DateTime>(DateTime.now())

    const toastContainer:MaybeElementRef = ref(null);

    onClickOutside(toastContainer, event => limit.value = 5);

    const pauseProgress = (messageData:ToastOptionsInterface) => {
        if (messageData.timeout <= 0) {
            return
        }

        messageData.paused = true
    }

    const resumeProgress = (messageData:ToastOptionsInterface) => {
        if (messageData.timeout <= 0) {
            return
        }

        messageData.paused = false

        toast.startTimeout(messageData, messageData.timeout * messageData.progress)
    }



    const timeAgo = (message:ToastMessageInterface) => {
        let timeDifference = timeNow.value.diff(message.createdAt).milliseconds
        if (timeDifference < 60000) {
            return 'A few seconds'
        }

        return humanizeDuration(timeDifference, { round: true, largest: 1 })
    }


    watch(
        toastMessages.value,
        () => {
            if (Object.keys(toastMessages.value)?.length > 0) {

                Object.values(toastMessages.value)?.forEach((item: ToastMessageInterface): void => {
                    toast[item.type](item.message);
                });

            }
        },
        { deep: true }
    );

    onMounted(() => {
        setInterval(() => timeNow.value = DateTime.now(), 1000)
    })
</script>

<style scoped>
.toast-container {
    position: fixed;
    top: 20px;
    bottom: 20px;
    right: 20px;
    min-width: 350px;
    z-index: 9999;
}
.toast-enter-active {
    transition: opacity 1s;
}
.toast-leave-active {
    transition: opacity 0.3s;
}
.toast-enter, .toast-leave-to {
    opacity: 0;
}
</style>